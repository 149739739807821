.home-content {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.home-buttons {
    position: absolute;
}

.home-logo {
    position: absolute;
    top: 50px;
    left: 50px;
    cursor: pointer;
    user-select: none;
}

.home-line-up {
    height: 300px;
    top: 0;
    left: 50%;
    border-left: 1px solid #ABABAB66;
    position: absolute;
}

.home-line-down {
    height: 200px;
    bottom: 0;
    left: 50%;
    border-left: 1px solid #ABABAB66;
    position: absolute;
}

.home-title {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    z-index: 5;
}

.home-subtext {
    color: #0F3A57;
    max-width: 24em;
    padding-left: 1.5rem;
    padding-top: 130px;
    font-size: 18px;
    font-weight: 500;
    font-family: "Exo 2";
}

.home-subtext-design {
    margin-top: 0.5rem !important;
    background-image: url('../assets/Yellow.png');
    background-repeat: no-repeat;
    padding: 0.25rem 1.5rem;
    font-family: "Exo 2";
    font-weight: 700;
    font-size: 18px;
}

.home-title h1 {
    font-family: "Roboto";
    font-weight: 500;
    color: #0F3A57;
}

/* Mobile */
@media only screen and (max-width: 480px) {
    .home {
        margin-top:80px;
    }
    .home-logo {
        margin-top: 30px;
        display: none;
    }
    .home-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .home-buttons {
        flex-direction: column;
        align-items: center;
        left: 0;
        right: 0 !important;
        gap: 30px;
        margin: 30px 0;
        
        position:  relative !important;
        top: unset !important;
        left: unset !important;
        right: unset !important;
        bottom: unset !important;
    }

    .home-subtext {
        position:  relative !important;
        top: unset !important;
        left: unset !important;
        right: unset !important;
        bottom: unset !important;

    }
    .home-subtext-design {
        position:  relative !important;
        top: unset !important;
        left: unset !important;
        right: unset !important;
        bottom: unset !important;

    }

    .home-logo {
        position:  relative !important;
        top: unset !important;
        left: unset !important;
        right: unset !important;
        bottom: unset !important;
    }

    .home-subtext {
        padding-top: 30px !important;
        padding-right: 1.5rem !important;
    }

    .home-line-up {
        height: unset !important;
        position:  relative !important;
        top: unset !important;
        left: -1px !important;
        right: unset !important;
        bottom: unset !important;
    }

    .home-line-down {
        display: none;
    }

    .home {
        display: flex;
        position: relative;
        height: 100vh;
        min-height: 800px;
    }


    .home-title {
        position:  relative !important;
        top: unset !important;
        left: unset !important;
        right: unset !important;
        bottom: unset !important;
        margin-top: 30px !important;
        margin-bottom: 30px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .home-title h1 {
        max-width: 420px;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.06em;
    }
}

/* Tablets */
@media only screen and (min-width: 481px) and (max-width:768px) {
    .home {
        margin-top:80px;
    }
    .home-buttons {
        flex-direction: column;
        align-items: center;
        left: 0;
        right: 0 !important;
        gap: 40px;
        margin: auto;
    }

    .home-logo {
        left: 50px !important;
        top: 15px !important;
        width: 130px !important;
        display: none;
    }

    .home-subtext {
        padding-top: 70px !important;
    }

    .home-line-up {
        left: 20px !important;
        height: 250px !important;
    }

    .home-line-down {
        left: 20px !important;
    }

    .home {
        display: flex;
        position: relative;
        height: 100vh;
        min-height: 800px;
    }


    .home-title {
        top: 0px !important;
    }

    .home-title h1 {
        width: 420px;
        font-size: 48px;
        line-height: 64px;
        letter-spacing: -0.06em;
    }
}

/* Small screens */
@media only screen and (min-width: 769px) and (max-width:1024px) {
    .home-subtext {
        padding-top: 50px !important;
    }

    .home-line-up {
        left: 35% !important;
    }

    .home-line-down {
        left: 35% !important;
    }

    .home {
        display: flex;
        position: relative;
        height: 100vh;
        min-height: 800px;
    }

    .home-title h1 {
        width: 580px;
        font-size: 64px;
        line-height: 82px;
        letter-spacing: -0.06em;
    }
}

/* Large screens */
@media only screen and (min-width: 1025px) and (max-width:1200px) {
    .home-line-up {
        left: 35% !important;
    }

    .home-line-down {
        left: 35% !important;
    }

    .home {
        display: flex;
        position: relative;
        height: 100vh;
        min-height: 937px;
    }

    .home-title h1 {
        width: 800px;
        font-size: 88px;
        line-height: 100px;
        letter-spacing: -0.06em;
    }
}

/* Extra large screens */
@media only screen and (min-width: 1201px) {

    .home {
        display: flex;
        position: relative;
        height: 100vh;
        min-height: 937px;
    }

    .home-title h1 {
        width: 953px;
        font-size: 123px;
        line-height: 139px;
        letter-spacing: -0.06em;
    }

}