.home-content {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home-buttons {
  position: absolute;
}

.home-logo {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: 50px;
  left: 50px;
}

.home-line-up {
  height: 300px;
  border-left: 1px solid #ababab66;
  position: absolute;
  top: 0;
  left: 50%;
}

.home-line-down {
  height: 200px;
  border-left: 1px solid #ababab66;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.home-title {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 5;
  margin: auto;
  position: absolute;
  inset: 100px 0 0;
}

.home-subtext {
  color: #0f3a57;
  max-width: 24em;
  padding-top: 130px;
  padding-left: 1.5rem;
  font-family: "Exo 2";
  font-size: 18px;
  font-weight: 500;
}

.home-subtext-design {
  background-image: url("Yellow.2a6e708c.png");
  background-repeat: no-repeat;
  padding: .25rem 1.5rem;
  font-family: "Exo 2";
  font-size: 18px;
  font-weight: 700;
  margin-top: .5rem !important;
}

.home-title h1 {
  color: #0f3a57;
  font-family: Roboto;
  font-weight: 500;
}

@media only screen and (max-width: 480px) {
  .home {
    margin-top: 80px;
  }

  .home-logo {
    margin-top: 30px;
    display: none;
  }

  .home-content {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .home-buttons {
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin: 30px 0;
    left: 0;
    right: 0 !important;
    top: unset !important;
    left: unset !important;
    right: unset !important;
    bottom: unset !important;
    position: relative !important;
  }

  .home-subtext, .home-subtext-design, .home-logo {
    top: unset !important;
    left: unset !important;
    right: unset !important;
    bottom: unset !important;
    position: relative !important;
  }

  .home-subtext {
    padding-top: 30px !important;
    padding-right: 1.5rem !important;
  }

  .home-line-up {
    height: unset !important;
    top: unset !important;
    left: -1px !important;
    right: unset !important;
    bottom: unset !important;
    position: relative !important;
  }

  .home-line-down {
    display: none;
  }

  .home {
    height: 100vh;
    min-height: 800px;
    display: flex;
    position: relative;
  }

  .home-title {
    top: unset !important;
    left: unset !important;
    right: unset !important;
    bottom: unset !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    position: relative !important;
  }

  .home-title h1 {
    max-width: 420px;
    letter-spacing: -.06em;
    font-size: 40px;
    line-height: 48px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .home {
    margin-top: 80px;
  }

  .home-buttons {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin: auto;
    left: 0;
    right: 0 !important;
  }

  .home-logo {
    display: none;
    width: 130px !important;
    top: 15px !important;
    left: 50px !important;
  }

  .home-subtext {
    padding-top: 70px !important;
  }

  .home-line-up {
    height: 250px !important;
    left: 20px !important;
  }

  .home-line-down {
    left: 20px !important;
  }

  .home {
    height: 100vh;
    min-height: 800px;
    display: flex;
    position: relative;
  }

  .home-title {
    top: 0 !important;
  }

  .home-title h1 {
    width: 420px;
    letter-spacing: -.06em;
    font-size: 48px;
    line-height: 64px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .home-subtext {
    padding-top: 50px !important;
  }

  .home-line-up, .home-line-down {
    left: 35% !important;
  }

  .home {
    height: 100vh;
    min-height: 800px;
    display: flex;
    position: relative;
  }

  .home-title h1 {
    width: 580px;
    letter-spacing: -.06em;
    font-size: 64px;
    line-height: 82px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .home-line-up, .home-line-down {
    left: 35% !important;
  }

  .home {
    height: 100vh;
    min-height: 937px;
    display: flex;
    position: relative;
  }

  .home-title h1 {
    width: 800px;
    letter-spacing: -.06em;
    font-size: 88px;
    line-height: 100px;
  }
}

@media only screen and (min-width: 1201px) {
  .home {
    height: 100vh;
    min-height: 937px;
    display: flex;
    position: relative;
  }

  .home-title h1 {
    width: 953px;
    letter-spacing: -.06em;
    font-size: 123px;
    line-height: 139px;
  }
}

/*# sourceMappingURL=index.e3ae8816.css.map */
